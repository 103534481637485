module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sithara Krishnakumar","short_name":"Sithara","description":"The official website of singer-songwriter Sithara Krishnakumar featuring stories, articles, interviews, tour dates and more.","start_url":"/","background_color":"#fff","theme_color":"#1a1067","display":"standalone","icon":"/opt/build/repo/src/images/icon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"058e395dad82e37890e40212c7ee2cf0"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
